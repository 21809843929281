import { get, put } from '../axios';

const REQUEST_MAPPING = '/layout';

export default {
  /**
   * get all layouts from client
   * 
   * @param {string} clientId
   * @returns {Promise<Response>}
   */
  layoutsFromClient(clientId) {
    const url = `${REQUEST_MAPPING}/${clientId}/template`;

    return get(url);
  },

  /**
   * get layout by id
   * 
   * @param {string} clientId
   * @param {number} layoutId
   * @returns {Promise<Response>}
   */
  layoutById(clientId, layoutId) {
    const url = `${REQUEST_MAPPING}/${clientId}/template/${layoutId}`;

    return get(url);
  },

  /**
   * get layout by id
   * 
   * @param {string} clientId
   * @param {string} clientElementId
   * @param {string} subelementId  
   * @returns {Promise<Response>}
   */
  formularLayout(clientId, clientElementId, subelementId) {
    ///{clientId}/formulare/{clientElementId}/{subElementId}
    const url = `${REQUEST_MAPPING}/${clientId}/formulare/${clientElementId}/${subelementId}`;

    return get(url);
  },

  /**
   * 
   * @param {string} clientId 
   * @param {string} clientElementId 
   * @param {string} subelementId 
   * @param {number}  formularRevision
   * @param {ClientElementFormularLayout} layout 
   * @returns {Promise<Response>}
   */
  setFormularLayout(clientId, clientElementId, subelementId, formularRevision, layout) {
    // eslint-disable-next-line max-len
    const url = `${REQUEST_MAPPING}/${clientId}/formulare/${clientElementId}/${subelementId}/${formularRevision}`;

    return put(url, layout);
  },

  /**
   * set layout for clientElement
   * 
   * @param {string} clientId
   * @param {string} clientElementId
   * @param {string} subElementId
   * @param {ClientElementLayout} layout 
   * @returns {Promise<Response>}
   */
  setLayoutForClientElement(clientId, clientElementId, subElementId, layout) {
    const url = `${REQUEST_MAPPING}/${clientId}/setForClientElement/${clientElementId}`
      + `/${subElementId}`;

    return put(url, layout);
  },

  /**
   * set layout key for clientElement
   * 
   * @param {string} clientId
   * @param {string} templateId
   * @param {number} revision
   * @param {Object} body
   * @returns {Promise<Response>}
   */
  setLayoutKeyForLayout(clientId, templateId, revision, body) {
    const url = `${REQUEST_MAPPING}/${clientId}/templatePart/${templateId}/${parseInt(revision)}`;

    return put(url, body);
  },

  /**
   * set name for layout
   * 
   * @param {string} clientId
   * @param {string} templateId
   * @param {string} name
   * @returns {Promise<Response>}
   */
  setLayoutName(clientId, templateId, name) {
    const url = `${REQUEST_MAPPING}/${clientId}/template/${templateId}/name`;

    return put(url, name);
  },

  /**
   * insert new layout
   * 
   * @param {string} clientId
   * @param {ClientElementLayout} layout
   * @returns {Promise<Response>}
   */
  insertLayout(clientId, layout) {
    const url = `${REQUEST_MAPPING}/${clientId}/template`;

    return put(url, layout);
  },

  /**
   * duplicate existing layout
   * 
   * @param {string} clientId
   * @param {string} templateId
   * @param {string} clientElementId
   * @param {string} subElementId
   * @returns {Promise<Response>}
   */
  duplicateLayout(clientId, templateId, clientElementId, subElementId) {
    const url = `${REQUEST_MAPPING}/${clientId}/template/${templateId}/duplicate` 
      + `/${clientElementId}/${subElementId}`;

    return put(url);
  }
};
