export default class DOTClientElementLayout {
  /**
   * @param {*} layout
   * @param {*} ueberschriftSchriftgroesse
   * @param {*} textSchriftgroesse
   * @param {*} ueberschriftSchriftart
   * @param {*} textSchriftart
   * @param {*} abstandOben
   * @param {*} abstandUnten
   * @param {*} abstandRechts
   * @param {*} abstandLinks
   * @param {*} abstandAbsatz
   * @param {*} abstandVorUeberschrift
   * @param {*} abstandSpalten
   * @param {*} textSchriftFarbe
   * @param {*} ueberschriftSchriftFarbe
   * @param {*} ueberschriftHintergrundFarbe
   * @param {*} ueberschriftAbstand
   * @param {*} ueberschriftUnterstrichFarbe
   * @param {*} ueberschriftUnterstrichStaerke
   * @param {*} ueberschriftAusrichtung
   * @param {*} ueberschriftSchrifteffekte
   * 
   * @param {*} logo
   * @param {*} breiteLogo
   * @param {*} platzierungLogo
   * @param {*} kopfzeileText
   * @param {*} kopfzeileTextGroesse
   * @param {*} kopfzeileTextFarbe
   * @param {*} kopfzeilePositionierung
   * 
   * @param {*} formularHintergrundfarbe
   * @param {*} formularRandPosition
   * @param {*} formularRandStaerke
   * @param {*} formularRandRadius
   * @param {*} formularRandFarbe
   * 
   * @param {*} tabelleUmrandung
   * @param {*} tabelleUmrandungStaerke
   * @param {*} tabelleUmrandungFarbe
   * @param {*} tabelleHintergrundFarbe
   * @param {*} tabelleKopfzeileEffekte
   * @param {*} tabelleKopfzeileSchriftFarbe
   * @param {*} tabelleKopfHintergrundFarbe
   * 
   * @param {*} fusszeileSpaltenlayout
   * @param {*} fusszeileTexte
   * @param {*} fusszeileNummerierung
   * @param {*} fusszeileTexteGroesse
   * @param {*} fusszeileTexteFarbe
   */
  constructor(
    layout,
    ueberschriftSchriftgroesse,
    textSchriftgroesse,
    ueberschriftSchriftart,
    textSchriftart,
    abstandOben,
    abstandUnten,
    abstandRechts,
    abstandLinks,
    abstandAbsatz,
    abstandVorUeberschrift,
    abstandSpalten,
    textSchriftFarbe,
    ueberschriftSchriftFarbe,
    ueberschriftHintergrundFarbe,
    ueberschriftAbstand,
    ueberschriftUnterstrichFarbe,
    ueberschriftUnterstrichStaerke,
    ueberschriftAusrichtung,
    ueberschriftSchrifteffekte,

    logo,
    breiteLogo,
    platzierungLogo,
    kopfzeileText,
    kopfzeileTextGroesse,
    kopfzeileTextFarbe,
    kopfzeilePositionierung,

    formularHintergrundfarbe,
    formularRandPosition,
    formularRandStaerke,
    formularRandRadius,
    formularRandFarbe,

    tabelleUmrandung,
    tabelleUmrandungStaerke,
    tabelleUmrandungFarbe,
    tabelleHintergrundFarbe,
    tabelleKopfzeileEffekte,
    tabelleKopfzeileSchriftFarbe,
    tabelleKopfHintergrundFarbe,

    fusszeileSpaltenlayout,
    fusszeileTexte,
    fusszeileNummerierung,
    fusszeileTexteGroesse,
    fusszeileTexteFarbe
  ) {
    this.layout = layout;
    this.ueberschriftSchriftgroesse = ueberschriftSchriftgroesse;
    this.textSchriftgroesse = textSchriftgroesse;
    this.ueberschriftSchriftart = ueberschriftSchriftart;
    this.textSchriftart = textSchriftart;
    this.abstandOben = abstandOben;
    this.abstandUnten = abstandUnten;
    this.abstandRechts = abstandRechts;
    this.abstandLinks = abstandLinks;
    this.abstandAbsatz = abstandAbsatz;
    this.abstandVorUeberschrift = abstandVorUeberschrift;
    this.abstandSpalten = abstandSpalten;
    this.textSchriftFarbe = textSchriftFarbe;
    this.ueberschriftSchriftFarbe = ueberschriftSchriftFarbe;
    this.ueberschriftHintergrundFarbe = ueberschriftHintergrundFarbe;
    this.ueberschriftAbstand= ueberschriftAbstand;
    this.ueberschriftUnterstrichFarbe= ueberschriftUnterstrichFarbe;
    this.ueberschriftUnterstrichStaerke= ueberschriftUnterstrichStaerke;
    this.ueberschriftAusrichtung = ueberschriftAusrichtung;
    this.ueberschriftSchrifteffekte = ueberschriftSchrifteffekte;

    this.logo = logo;
    this.breiteLogo = breiteLogo;
    this.platzierungLogo = platzierungLogo;
    this.kopfzeileText = kopfzeileText;
    this.kopfzeileTextGroesse = kopfzeileTextGroesse;
    this.kopfzeileTextFarbe = kopfzeileTextFarbe;
    this.kopfzeilePositionierung = kopfzeilePositionierung;

    this.formularHintergrundfarbe = formularHintergrundfarbe;
    this.formularRandPosition = formularRandPosition;
    this.formularRandStaerke = formularRandStaerke;
    this.formularRandRadius = formularRandRadius;
    this.formularRandFarbe = formularRandFarbe;

    this.tabelleUmrandung = tabelleUmrandung;
    this.tabelleUmrandungStaerke = tabelleUmrandungStaerke;
    this.tabelleUmrandungFarbe = tabelleUmrandungFarbe;
    this.tabelleHintergrundFarbe = tabelleHintergrundFarbe;
    this.tabelleKopfzeileEffekte = tabelleKopfzeileEffekte;
    this.tabelleKopfzeileSchriftFarbe = tabelleKopfzeileSchriftFarbe;
    this.tabelleKopfHintergrundFarbe = tabelleKopfHintergrundFarbe;

    this.fusszeileSpaltenlayout = fusszeileSpaltenlayout;
    this.fusszeileTexte = fusszeileTexte;
    this.fusszeileNummerierung = fusszeileNummerierung;
    this.fusszeileTexteGroesse = fusszeileTexteGroesse;
    this.fusszeileTexteFarbe = fusszeileTexteFarbe;
  }

  static fromJson(json) {
    const {
      layout,
      ueberschriftSchriftgroesse,
      textSchriftgroesse,
      ueberschriftSchriftart,
      textSchriftart,
      abstandAbsatz,
      abstandVorUeberschrift,
      abstandSpalten,
      textSchriftFarbe,
      ueberschriftSchriftFarbe,
      ueberschriftHintergrundFarbe,
      ueberschriftAbstand,
      ueberschriftUnterstrichFarbe,
      ueberschriftUnterstrichStaerke,
      ueberschriftAusrichtung,
      ueberschriftSchrifteffekte,

      logo,
      breiteLogo,
      platzierungLogo,
      kopfzeileText,
      kopfzeileTextGroesse,
      kopfzeileTextFarbe,
      kopfzeilePositionierung,
      padding,

      formularHintergrundfarbe,
      formularRandPosition,
      formularRandStaerke,
      formularRandRadius,
      formularRandFarbe,

      tabelleUmrandung,
      tabelleUmrandungStaerke,
      tabelleUmrandungFarbe,
      tabelleHintergrundFarbe,
      tabelleKopfzeileEffekte,
      tabelleKopfzeileSchriftFarbe,
      tabelleKopfHintergrundFarbe,

      fusszeileSpaltenlayout,
      fusszeileTexte,
      fusszeileNummerierung,
      fusszeileTexteGroesse,
      fusszeileTexteFarbe
    } = json;

    const abstandOben = padding?.top || json.abstandOben;
    const abstandUnten = padding?.bottom || json.abstandUnten;
    const abstandRechts = padding?.right || json.abstandRechts;
    const abstandLinks = padding?.left || json.abstandLinks;

    return new DOTClientElementLayout(
      layout,
      ueberschriftSchriftgroesse,
      textSchriftgroesse,
      ueberschriftSchriftart,
      textSchriftart,
      abstandOben,
      abstandUnten,
      abstandRechts,
      abstandLinks,
      abstandAbsatz,
      abstandVorUeberschrift,
      abstandSpalten,
      textSchriftFarbe,
      ueberschriftSchriftFarbe,
      ueberschriftHintergrundFarbe,
      ueberschriftAbstand,
      ueberschriftUnterstrichFarbe,
      ueberschriftUnterstrichStaerke,
      ueberschriftAusrichtung,
      ueberschriftSchrifteffekte,

      logo,
      breiteLogo,
      platzierungLogo,
      kopfzeileText,
      kopfzeileTextGroesse,
      kopfzeileTextFarbe,
      kopfzeilePositionierung,

      formularHintergrundfarbe,
      formularRandPosition,
      formularRandStaerke,
      formularRandRadius,
      formularRandFarbe,

      tabelleUmrandung,
      tabelleUmrandungStaerke,
      tabelleUmrandungFarbe,
      tabelleHintergrundFarbe,
      tabelleKopfzeileEffekte,
      tabelleKopfzeileSchriftFarbe,
      tabelleKopfHintergrundFarbe,

      fusszeileSpaltenlayout,
      fusszeileTexte,
      fusszeileNummerierung,
      fusszeileTexteGroesse,
      fusszeileTexteFarbe
    );
  }

  toJSON() {
    return {
      layout: this.layout,
      ueberschriftSchriftgroesse: this.ueberschriftSchriftgroesse,
      textSchriftgroesse: this.textSchriftgroesse,
      ueberschriftSchriftart: this.ueberschriftSchriftart,
      textSchriftart: this.textSchriftart,
      abstandOben: this.abstandOben,
      abstandUnten: this.abstandUnten,
      abstandRechts: this.abstandRechts,
      abstandLinks: this.abstandLinks,
      abstandAbsatz: this.abstandAbsatz,
      abstandVorUeberschrift: this.abstandVorUeberschrift,
      abstandSpalten: this.abstandSpalten,
      textSchriftFarbe: this.textSchriftFarbe,
      ueberschriftSchriftFarbe: this.ueberschriftSchriftFarbe,
      ueberschriftHintergrundFarbe: this.ueberschriftHintergrundFarbe,
      ueberschriftAbstand: this.ueberschriftAbstand,
      ueberschriftUnterstrichFarbe: this.ueberschriftUnterstrichFarbe,
      ueberschriftUnterstrichStaerke: this.ueberschriftUnterstrichStaerke,
      ueberschriftAusrichtung: this.ueberschriftAusrichtung,
      ueberschriftSchrifteffekte: this.ueberschriftSchrifteffekte,

      logo: this.logo,
      breiteLogo: this.breiteLogo,
      platzierungLogo: this.platzierungLogo,
      kopfzeileText: this.kopfzeileText,
      kopfzeileTextGroesse: this.kopfzeileTextGroesse,
      kopfzeileTextFarbe: this.kopfzeileTextFarbe,
      kopfzeilePositionierung: this.kopfzeilePositionierung,

      formularHintergrundfarbe: this.formularHintergrundfarbe,
      formularRandPosition: this.formularRandPosition,
      formularRandStaerke: this.formularRandStaerke,
      formularRandRadius: this.formularRandRadius,
      formularRandFarbe: this.formularRandFarbe,

      tabelleUmrandung: this.tabelleUmrandung,
      tabelleUmrandungStaerke: this.tabelleUmrandungStaerke,
      tabelleUmrandungFarbe: this.tabelleUmrandungFarbe,
      tabelleHintergrundFarbe: this.tabelleHintergrundFarbe,
      tabelleKopfzeileEffekte: this.tabelleKopfzeileEffekte,
      tabelleKopfzeileSchriftFarbe: this.tabelleKopfzeileSchriftFarbe,
      tabelleKopfHintergrundFarbe: this.tabelleKopfHintergrundFarbe,

      fusszeileSpaltenlayout: this.fusszeileSpaltenlayout,
      fusszeileTexte: this.fusszeileTexte,
      fusszeileNummerierung: this.fusszeileNummerierung,
      fusszeileTexteGroesse: this.fusszeileTexteGroesse,
      fusszeileTexteFarbe: this.fusszeileTexteFarbe
    };
  }
}

