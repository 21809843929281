import { isJSON } from '@st/utils-js';

export default class ClientElementFormularLayout {
  constructor(
    breite = 100,
    zeilenumbruch = false
  ) {
    this.breite = breite;
    this.zeilenumbruch = zeilenumbruch;
  }

  static fromJSON(value) {
    if(value instanceof ClientElementFormularLayout) return value;
    if (!isJSON(value)) return null;

    const {
      breite,
      zeilenumbruch
    } = value;

    return new ClientElementFormularLayout(
      breite,
      zeilenumbruch
    );
  }

  toJSON() {
    return {
      breite: this.breite,
      zeilenumbruch: this.zeilenumbruch
    };
  }

  get isValidBreite() {
    return typeof this.breite === 'number'
      && this.breite >= 0
      && this.breite <= 100;
  }

  get isValidZeilenumbruch() {
    return typeof this.zeilenumbruch === 'boolean';
  }

  get isValid() {
    const valid = this.isValidBreite
      && this.isValidZeilenumbruch;

    if(!valid) this.logInvalidProps();

    return valid;
  }

  logInvalidProps() {
    if(!this.isValidBreite) console.log('Breite is not valid');
    if(!this.isValidZeilenumbruch) console.log('Zeilenumbruch is not valid');
  }
}
