import MandantDokumentElementSubelementSections from '@src/model/MandantDokumentElementSubelementSections';
import AenderungsmodusPatch from '@src/model/aenderungsmodus/AenderungsmodusPatch';
import AenderungsmodusSection from '@src/model/aenderungsmodus/AenderungsmodusSection';
import { applyPatchesOnClientElement } from '@src/utils/applyPatchesOnClientElement';
import { deepClone, isEmptyOrNullish } from '@st/utils-js';
import moment from 'moment';
import { Node } from 'prosemirror-model';
import AenderungsmodusComment from '@src/model/aenderungsmodus/AenderungsmodusComment';
import TextContentSchema from '../../../../components/prosemirror/schemas/TextContentSchema';
import KeycloakUser from '../../../../model/KeycloakUser';
import MandantDokumentElement from '../../../../model/MandantDokumentElement';
import MandantDokumentVertrag from '../../../../model/MandantDokumentVertrag';
import Tag from '../../../../model/Tag';
import AenderungsmodusHistory from '../../../../model/aenderungsmodus/AenderungsmodusHistory';
import types from '../../../types';

const mandantEditorMutations = types.mutations.mandantEditor;

export default {
  // SET
  [mandantEditorMutations.SET_CLIENT_ELEMENT](state, clientElement) {
    const tmpClientElement = clientElement instanceof MandantDokumentElement
      ? clientElement
      : MandantDokumentElement.fromJSON(clientElement);
    state.clientElement = tmpClientElement;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENTS](state, { clientElements }) {
    const mappedClientElements = clientElements
      .map((indiDoc) => ({
        ...indiDoc,
        externResponsibilities: indiDoc.externResponsibilities.map(
          (resp) => KeycloakUser.fromJSON(resp)
        ),
        bbhResponsibility: KeycloakUser.fromJSON(indiDoc.bbhResponsibility),
        tags: indiDoc.tags.map((tag) => Tag.fromJSON(tag))
      }))
      .map((indiDoc) => MandantDokumentVertrag.fromJSON(indiDoc));
    state.clientElements = mappedClientElements?.slice();
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_NAME](state, {
    clientElementId,
    clientElementName
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    clientElement.name = clientElementName;

    state.clientElements.splice();
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_NOTES](state, {
    clientElementId,
    clientElementNotes
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    clientElement.notizen = clientElementNotes;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_TEMPLATES](state, {subElementId, templateId}) {
    state.clientElement.templates[subElementId] = templateId;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_RELEASE_FLAG](state, {
    clientElementId,
    freigabeStatus
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    clientElement.freigabeStatus = freigabeStatus;
    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
    state.clientElement.freigabeStatus = freigabeStatus;
  },
  [mandantEditorMutations.SET_CURRENT_CLIENT_ELEMENT_ID](state, { currentClientElementId }) {
    state.currentClientElementId = currentClientElementId;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_TAGS](state, { clientElementId, tags }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    clientElement.tags = tags;
    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
    state.clientElement.tags = tags;
  },
  [mandantEditorMutations.SET_REVISIONS_ARRAY](state, {
    revisionsArray
  }) {
    state.revisionsArray = revisionsArray;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_LAYOUT](state, {
    layout,
    sectionLayouts
  }) {
    state.liveViewDocLayout = layout;
    state.liveViewSectionLayouts = sectionLayouts ?? state.liveViewSectionLayouts;
    state.liveViewSectionLayoutsChangedCounter += 1;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_DOC_LAYOUT_NAME](state, name) {
    state.liveViewDocLayoutName = name;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_DOC_LAYOUT_REVISION](state, revision) {
    state.liveViewDocLayoutRevision = revision;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_LAYOUT_KEY_VALUE](state, {
    key,
    value
  }) {
    if(key === 'abstandOben') {
      state.liveViewDocLayout.padding.top = value;
    } else if (key === 'abstandUnten') {
      state.liveViewDocLayout.padding.bottom = value;
    } else if (key === 'abstandRechts') {
      state.liveViewDocLayout.padding.right = value;
    } else if (key === 'abstandLinks') {
      state.liveViewDocLayout.padding.left = value;
    } else {
      state.liveViewDocLayout[key] = value;
    }
    state.liveViewSectionLayoutsChangedCounter += 1;
  },
  [mandantEditorMutations.SET_CLIENT_ELEMENT_SECTION_LAYOUT](state, {
    sectionId,
    layout
  }) {
    const sectionLayouts = state.liveViewSectionLayouts;
    const sectionLayoutIndex = sectionLayouts.findIndex(
      (sectionLayout) => sectionLayout.sectionId === sectionId
    );
    if (sectionLayoutIndex === -1) {
      sectionLayouts.push(layout);
    } else {
      sectionLayouts[sectionLayoutIndex] = layout;
    }
    state.liveViewSectionLayouts = sectionLayouts;
    state.liveViewSectionLayoutsChangedCounter += 1;
  },
  [mandantEditorMutations.DELETE_CLIENT_ELEMENT_SECTION_LAYOUT](state, {
    sectionId
  }) {
    const sectionLayouts = state.liveViewSectionLayouts;
    const sectionLayoutIndex = sectionLayouts.findIndex(
      (sectionLayout) => sectionLayout.sectionId === sectionId
    );
    if (sectionLayoutIndex !== -1) {
      sectionLayouts.splice(sectionLayoutIndex, 1);
    }
    state.liveViewSectionLayouts = sectionLayouts;
    state.liveViewSectionLayoutsChangedCounter += 1;
  },

  [mandantEditorMutations.SET_LIVE_VIEW_DOC_FORMULAR_LAYOUTS](state, formulare) {
    state.liveViewDocFormularLayouts = formulare;
    state.liveViewSectionLayoutsChangedCounter += 1;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_DOC_FORMULAR_LAYOUT_REVISION](state, revision) {
    state.liveViewDocFormularLayoutRevision = revision;
  },
  [mandantEditorMutations.SET_SELECTED_LIVE_VIEW_FORMULAR_INPUT_ELEMENT](state, element) {
    state.selectedLiveViewFormularInputElement = element;
  },

  // UPDATE PROZESS

  [mandantEditorMutations.SET_MANDANT_COMPARE_ELEMENT](state, { mandantCompareElement }) {
    state.mandantCompareElement = mandantCompareElement;
  },
  [mandantEditorMutations.OVERWRITE_SECTION_WITH_UPDATE](state, { oldSectionId }) {
    const newSectionId = state.clientElement.clientElementUpdates[oldSectionId];
    const newSection = state.clientElement.clientElementUpdateSections[newSectionId];

    const subElementId = state.currentClientElementId;
    const subElement = state.clientElement.clientElement.subElements[subElementId];

    const { content } = subElement;
    const oldSectionIdIndex = content.order.findIndex((sectionId) => sectionId === oldSectionId);
    content.order.splice(oldSectionIdIndex, 1, newSectionId);
    content.contents[newSectionId] = newSection;

    if (oldSectionId !== newSectionId) {
      delete content.contents[oldSectionId];

      const { clientElementOriginMapUpdates } = state.clientElement;
      content.originMap[newSectionId] = clientElementOriginMapUpdates[newSectionId];
      delete content.originMap[oldSectionId];
    }

    delete state.clientElement.clientElementUpdates[oldSectionId];

    delete state.clientElement.clientElementOriginMapUpdates[newSectionId];
    delete state.clientElement.clientElementUpdateSections[newSectionId];
  },

  [mandantEditorMutations.SET_CLIENT_ELEMENT_ZWANGSUPDATE](state, payload) {
    state.clientElementZwangsupdate = payload;
  },

  [mandantEditorMutations.REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY](state, key) {
    const keyExists = Object.keys(state.clientElementZwangsupdate).includes(key);
    if (keyExists) {
      delete state.clientElementZwangsupdate[key];
    }
  },

  [mandantEditorMutations.SET_CLIENT_ELEMENT_UPDATES](state, { updates }) {
    state.clientElement.clientElementUpdateSections = updates?.clientElementUpdateSections;
    state.clientElement.clientElementUpdates = updates?.clientElementUpdates;
    state.clientElement.clientElementOriginMapUpdates = updates?.clientElementOriginMapUpdates;
  },

  [mandantEditorMutations.SET_UPDATE_COMPLETED](state, { clientElementId }) {
    state.updateIsFinishedCounter = 0;
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    if (isEmptyOrNullish(clientElement)) return;

    clientElement.update = false;
    state.clientElements.splice();
  },

  [mandantEditorMutations.INCREASE_UPDATE_IS_FINISHED_COUNTER](state) {
    state.updateIsFinishedCounter += 1;
  },

  // UPDATE
  [mandantEditorMutations.UPDATE_MANDANT_ORDER](state, { dokumentId, order }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.order = order;
  },
  [mandantEditorMutations.UPDATE_MANDANT_SECTION](state, { dokumentId, sectionId, content }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.contents[sectionId].content = content;
    if (!mandantenDokument.content.originMap[sectionId]) {
      mandantenDokument.content.originMap[sectionId] = {};
    }
    mandantenDokument.content.originMap[sectionId].modified = true;
  },
  [mandantEditorMutations.UPDATE_SECTION_ATTRS](state, { dokumentId, sectionId, attrs }) {
    const mandantenDokument = state.clientElement.clientElement.subElements[dokumentId];
    mandantenDokument.content.contents[sectionId].attrs = attrs;
    if (!mandantenDokument.content.originMap[sectionId]) {
      mandantenDokument.content.originMap[sectionId] = {};
    }
    mandantenDokument.content.originMap[sectionId].modified = true;
  },
  [mandantEditorMutations.UPDATE_MANDANT_DOKUMENT](state, { dokumentId, newDokument }) {
    state.clientElement.clientElement.subElements[dokumentId] = newDokument;
  },
  [mandantEditorMutations.UPDATE_MANDANT_REVISION](state) {
    state.clientElement.revision += 1;
  },
  [mandantEditorMutations.UPDATE_CLIENT_ELEMENT_DOC_NAME](state, { dokumentId, currentDocName }) {
    state.clientElement.clientElement.subElements[dokumentId].name = currentDocName;
  },

  [mandantEditorMutations.UPDATE_LEVEL_IN_CLIENT_ELEMENT](state, { sectionId, newLevel }) {
    const { currentClientElementId } = state;
    const mandantenDokument = state.clientElement.clientElement.subElements[currentClientElementId];
    mandantenDokument.content.contents[sectionId].attrs.level = newLevel;
  },

  [mandantEditorMutations.UPDATE_TAG_IN_CLIENT_ELEMENTS](state, { tagId, color, name }) {
    if (!tagId || !color || !name) return;

    state.clientElements = state.clientElements.map((clientElement) => {
      const updatedTags = clientElement.tags.map((tag) => {
        if (tag.id === tagId) {
          return { ...tag, color, name };
        }
        return tag;
      });
      return { ...clientElement, tags: updatedTags };
    });
  },

  // ADD
  [mandantEditorMutations.ADD_CLIENT_ELEMENT_TO_CLIENT_ELEMENT_LIST](state, {
    clientElement
  }) {
    state.clientElements.push(clientElement);
  },

  [mandantEditorMutations.ADD_NACHFOLGER_TO_CLIENT_ELEMENT](state, {
    clientElementId,
    nachfolgerId,
    updateType
  }) {
    let clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );

    if (!clientElement) {
      clientElement = {
        clientElementId,
        clientElementNachfolger: []
      };
    }

    const nachfolger = {
      nachfolgerClientElementId: nachfolgerId,
      updateType
    };

    clientElement.clientElementNachfolger.push(nachfolger);

    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
  },

  [mandantEditorMutations.ADD_SUBELEMENT_TO_CLIENT_ELEMENT_LIST](state, {
    contract,
    newDocument
  }) {
    const clientElement = state.clientElements
      .find((document) => document.clientElementId === contract.clientElementId);
    clientElement
      .subElements
      .push(newDocument);

    clientElement.revision = contract.revision;
  },

  [mandantEditorMutations.ADD_CLAUSE_TO_CURRENT_MANDANT_DOCUMENT](state, {
    clause,
    sectionId
  }) {
    const { currentClientElementId } = state;
    const mandantenDokument = state.clientElement.clientElement.subElements[currentClientElementId];
    const clauseContent = clause.content.map((section) => section.content).flat();

    let previousPositionTextLength = 0;
    const comments = clause.content.reduce((acc, section) => {
      const copy = deepClone(section);
      const node = Node.fromJSON(TextContentSchema, { ...copy, type: 'section' });

      const newComments = section.attrs.comments.reduce((result, comment) => {
        const noLength = comment.from !== comment.to;
        const positionOutOfRange = node.nodeSize >= comment.from;
        if (noLength || positionOutOfRange) {
          result.push({
            ...comment,
            from: comment.from + previousPositionTextLength,
            to: comment.to + previousPositionTextLength
          });
        }
        return result;
      }, []);

      acc.push(...newComments);

      previousPositionTextLength += node.content.size;
      return acc;
    }, []);

    mandantenDokument.content.contents[sectionId].content = clauseContent;
    mandantenDokument.content.contents[sectionId].attrs.comments = comments;
    mandantenDokument.content.originMap[sectionId].modified = true;
  },

  // REMOVE
  [mandantEditorMutations.REMOVE_SUBELEMENT_FROM_CLIENT_ELEMENT_LIST](state, {
    clientElementId,
    id,
    revision
  }) {
    const clientElement = state.clientElements
      .find((document) => document.clientElementId === clientElementId);

    clientElement
      .subElements
      .splice(
        clientElement.subElements.map((s) => s.id).indexOf(id),
        1
      );

    clientElement.revision = revision;
  },
  [mandantEditorMutations.REMOVE_SECTION_FROM_SUBELEMENT](state, {
    sectionId
  }) {
    const subElementId = state.currentClientElementId;

    const { content } = state.clientElement.clientElement.subElements[subElementId];
    if (!(content instanceof MandantDokumentElementSubelementSections)) return;
    content.removeSection(sectionId);

    const originMapKeys = Object.keys(
      state.clientElement?.clientElement?.subElements[subElementId]?.content.originMap || {}
    );
    if (originMapKeys.includes(sectionId)) {
      delete state.clientElement.clientElement.subElements[subElementId].content
        .originMap[sectionId];
    }
    removeUpdateFromClientElement(state, sectionId);
  },

  [mandantEditorMutations.REMOVE_UPDATE_FROM_CLIENT_ELEMENT](state, { sectionId }) {
    removeUpdateFromClientElement(state, sectionId);
  },

  // DEPRECATE
  [mandantEditorMutations.DEPRECATE_CLIENT_ELEMENT](state, { clientElementId }) {
    const indexOfDoc = state.clientElements.findIndex(
      (doc) => doc.clientElementId === clientElementId
    );
    state.clientElements.splice(indexOfDoc, 1);
  },

  // DELETE
  [mandantEditorMutations.DELETE_CLIENT_ELEMENT_RESPONSIBILITY](state, {
    clientElementId,
    keycloakUserUuid
  }) {
    const clientElement = state.clientElements.find(
      (tmpClientElement) => tmpClientElement.clientElementId === clientElementId
    );
    if (!clientElement) return;

    clientElement.externResponsibilities = clientElement.externResponsibilities.filter(
      (responsibility) => responsibility.id !== keycloakUserUuid
    );

    const indexOf = state.clientElements.indexOf(clientElement);

    state.clientElements.splice(indexOf, 1, clientElement);
  },
  [mandantEditorMutations.DELETE_TAG_FROM_ALL_CLIENT_ELEMENTS](state, { tagId }) {
    state.clientElements = state.clientElements.map((clientElement) => {
      const filteredTags = clientElement.tags.filter((tag) => tag.id !== tagId);
      return { ...clientElement, tags: filteredTags };
    });
  },

  // PUT
  [mandantEditorMutations.PUT_CLIENT_ELEMENT_RESPONSIBILITY](state, {
    clientElementId,
    keycloakUser
  }) {
    state.clientElements
      .find((document) => document.clientElementId === clientElementId)
      .externResponsibilities.push(keycloakUser);
  },
  [mandantEditorMutations.UPDATE_CLIENT_ELEMENT_REVISIONS_ARRAY](state, {
    revisionNr
  }) {
    state.revisionsArray.push({
      revisionNr,
      name: moment().format('DD.MM.YYYY HH:mm')
    });
    state.revisionsArray.sort(
      (a, b) => b.revisionNr - a.revisionNr
    );
  },

  [mandantEditorMutations.SET_LIVE_VIEW](state, liveView) {
    state.liveView = liveView;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_PT_TEXT](state, ptText) {
    state.liveViewDocLayout.ptText = ptText;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_PT_HEADING](state, ptHeading) {
    state.liveViewDocLayout.ptHeading = ptHeading;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_FONT_FAMILY_TEXT](state, fontFamilyText) {
    state.liveViewDocLayout.fontFamilyText = fontFamilyText;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_FONT_FAMILY_HEADING](state, fontFamilyHeading) {
    state.liveViewDocLayout.fontFamilyHeading = fontFamilyHeading;
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS](state, aenderungsmodus) {
    state.aenderungsmodus = aenderungsmodus;
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_HISTORY](state, aenderungsmodusHistory) {
    state.aenderungsmodusHistory = AenderungsmodusHistory.fromJSON(aenderungsmodusHistory);
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_HISTORY_UPDATE_COUNTER](state, counter) {
    state.aenderungsmodusHistoryUpdateCounter = counter;
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_SECTION_REVISION_AND_ADD_PATCH](state, {
    sectionId,
    revision,
    subRevision,
    patch
  }) {
    if (!(patch instanceof AenderungsmodusPatch) || !patch.valid) throw new Error('Invalid patch');

    const section = state.aenderungsmodusHistory.sections[sectionId];
    if (!section) {
      state.aenderungsmodusHistory.sections[sectionId] = new AenderungsmodusSection(
        revision,
        subRevision,
        [ patch ]
      );
      state.aenderungsmodusHistoryUpdateCounter += 1;
      return;
    }

    section.baseSubRevision = subRevision;

    if (section.baseRevision < revision) {
      section.baseRevision = revision;
      section.patches.push(patch);
      state.aenderungsmodusHistoryUpdateCounter += 1;
      return;
    }

    const patchIndex = section.patches.findIndex((p) => p.revision === revision);
    if (patchIndex === -1) throw new Error('Invalid patchIndex');
    section.patches[patchIndex].patch.push(...patch.patch);
    section.patches[patchIndex].modified = patch.modified;
    section.patches[patchIndex].subRevision = patch.subRevision;
    state.aenderungsmodusHistoryUpdateCounter += 1;
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_BASE](state, base) {
    if (isEmptyOrNullish(base)) {
      state.aenderungsmodusSelectedBase = {};
      return;
    }
    if (!(base instanceof AenderungsmodusPatch)) throw new Error('Invalid base');
    state.aenderungsmodusSelectedBase = base;
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_SELECTED_HEAD](state, head) {
    if (isEmptyOrNullish(head)) {
      state.aenderungsmodusSelectedHead = {};
      return;
    }
    if (!(head instanceof AenderungsmodusPatch)) throw new Error('Invalid head');
    state.aenderungsmodusSelectedHead = head;
  },

  [mandantEditorMutations.PREPARE_CLIENT_ELEMENT_FOR_AENDERUNGSMODUS](state, {
    originClientElement,
    subelementRecord,
    subElementId,
    aenderungsmodusSections
  }) {
    if (!(originClientElement instanceof MandantDokumentElement)) throw new Error('Invalid originClientElement');
    const originClientElementJson = originClientElement.toJSON();

    const mappedSubelementRecord = MandantDokumentElement.fromJSON({
      ...originClientElementJson,
      clientElement: {
        ...originClientElementJson.clientElement,
        subElements: {
          ...originClientElementJson.clientElement.subElements,
          [subElementId]: subelementRecord
        }
      }
    });

    state.aenderungsmodusOriginClientElement = MandantDokumentElement.fromJSON(
      deepClone(mappedSubelementRecord.toJSON())
    );

    mappedSubelementRecord.clientElement.subElements[subElementId].content.order = subelementRecord
      .sections.order;

    const updatedClientElement = applyPatchesOnClientElement(
      mappedSubelementRecord,
      aenderungsmodusSections,
      subElementId
    );
    state.clientElement = updatedClientElement;
    state.aenderungsmodusHistoryUpdateCounter += 1;
  },

  [mandantEditorMutations.ADD_COMMENT_TO_PATCH](state, {
    sectionId,
    revision,
    comment,
    user
  }) {
    const section = state.aenderungsmodusHistory.sections[sectionId];
    if (!section) return;
    const patch = section.patches.find((p) => p.revision === revision);
    if (!patch) return;
    const { id: userId, firstname, lastname } = user;
    const newComment = new AenderungsmodusComment(
      userId,
      comment,
      Number(moment().format('x')),
      firstname,
      lastname
    );
    if (!newComment.valid) throw new Error('Invalid comment');
    patch.comments.push(newComment);
  },

  [mandantEditorMutations.REMOVE_SECTION_FROM_AENDERUNGSMODUS_HISTORY](state, sectionId) {
    if (!sectionId || !state.aenderungsmodusHistory.sections[sectionId]) return;

    delete state.aenderungsmodusHistory.sections[sectionId];

    removeUpdateFromClientElement(state, sectionId);
  },

  [mandantEditorMutations.SET_AENDERUNGSMODUS_ORIGIN_CLIENT_ELEMENT_ORDER](state, order) {
    state.aenderungsmodusOriginClientElement.clientElement.subElements[
      state.currentClientElementId
    ].content.order = order;
  },

  [mandantEditorMutations.SET_LIVE_VIEW](state, liveView) {
    state.liveView = liveView;
  },
  [mandantEditorMutations.SET_LIVE_VIEW_RENDERED](state, liveViewRendered) {
    state.liveViewRendered = liveViewRendered;
  },
  [mandantEditorMutations.SET_LAYOUT_MODE](state, layoutMode) {
    state.layoutMode = layoutMode;
  }
};

function removeUpdateFromClientElement(state, sectionId) {
  const clientElementUpdateKeys = Object.keys(
    state.clientElement?.clientElementUpdates || {}
  );
  if (clientElementUpdateKeys.includes(sectionId)) {
    delete state.clientElement.clientElementUpdates[sectionId];
  }

  if(state.clientElement.clientElementOriginMapUpdates?.[sectionId]) {
    delete state.clientElement.clientElementOriginMapUpdates[sectionId];
  }

  if (state.clientElement.clientElementUpdateSections?.[sectionId]) {
    delete state.clientElement.clientElementUpdateSections[sectionId];
  }
}
