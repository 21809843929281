import { component as MODULE } from '../store/state';

const MUTATIONS = {
  SET_CLIENT_ELEMENT: `${MODULE}set_client_element`,
  SET_CLIENT_ELEMENTS: `${MODULE}set_client_elements`,
  SET_CLIENT_ELEMENT_NAME: `${MODULE}set_client_element_name`,
  SET_CLIENT_ELEMENT_NOTES: `${MODULE}set_client_element_notes`,
  SET_CLIENT_ELEMENT_TEMPLATES: `${MODULE}set_client_element_templates`,
  SET_CURRENT_CLIENT_ELEMENT_ID: `${MODULE}set_current_client_element_id`,
  SET_REVISIONS_ARRAY: `${MODULE}set_revisions_array`,

  SET_CLIENT_ELEMENT_LAYOUT: `${MODULE}set_client_element_layout`,
  SET_LIVE_VIEW_DOC_LAYOUT_NAME: `${MODULE}set_live_view_doc_layout_name`,
  SET_LIVE_VIEW_DOC_LAYOUT_REVISION: `${MODULE}set_live_view_doc_layout_revision`,
  SET_CLIENT_ELEMENT_LAYOUT_KEY_VALUE: `${MODULE}set_client_element_layout_key_value`,
  SET_CLIENT_ELEMENT_SECTION_LAYOUT: `${MODULE}set_client_element_section_layout`,
  DELETE_CLIENT_ELEMENT_SECTION_LAYOUT: `${MODULE}delete_client_element_section_layout`,

  SET_LIVE_VIEW_DOC_FORMULAR_LAYOUTS: `${MODULE}set_live_view_doc_formular_layouts`,
  SET_SELECTED_LIVE_VIEW_FORMULAR_INPUT_ELEMENT:
    `${MODULE}set_selected_live_view_formular_input_element`,
  SET_LIVE_VIEW_DOC_FORMULAR_LAYOUT_REVISION: `${MODULE}set_live_view_doc_formular_layout_revision`,

  SET_CLIENT_ELEMENT_RELEASE_FLAG: `${MODULE}set_client_element_release_flag`,

  SET_CLIENT_ELEMENT_TAGS: `${MODULE}set_client_element_tags`,

  // Update Prozess
  SET_MANDANT_COMPARE_ELEMENT: `${MODULE}set_mandant_compare_element`,
  OVERWRITE_SECTION_WITH_UPDATE: `${MODULE}overwrite_section_with_update`,
  SET_CLIENT_ELEMENT_ZWANGSUPDATE: `${MODULE}set_client_element_zwangsupdate`,
  REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY: `${MODULE}remove_client_element_zwangsupdate_key`,
  SET_UPDATE_COMPLETED: `${MODULE}set_update_completed`,
  INCREASE_UPDATE_IS_FINISHED_COUNTER: `${MODULE}set_check_if_update_is_finished_counter`,
  REMOVE_UPDATE_FROM_CLIENT_ELEMENT: `${MODULE}remove_update_from_client_element`,

  SET_CLIENT_ELEMENT_UPDATES: `${MODULE}set_client_element_updates`,

  UPDATE_MANDANT_ORDER: `${MODULE}update_mandant_order`,
  UPDATE_MANDANT_SECTION: `${MODULE}update_mandant_section`,
  UPDATE_SECTION_ATTRS: `${MODULE}update_section_attrs`,
  UPDATE_MANDANT_DOKUMENT: `${MODULE}update_mandant_dokument`,
  UPDATE_MANDANT_REVISION: `${MODULE}update_mandant_revision`,

  UPDATE_CLIENT_ELEMENT_DOC_NAME: `${MODULE}update_client_element_doc_name`,

  UPDATE_LEVEL_IN_CLIENT_ELEMENT: `${MODULE}update_level_in_client_element`,

  DEPRECATE_CLIENT_ELEMENT: `${MODULE}deprecate_client_element`,

  REMOVE_SUBELEMENT_FROM_CLIENT_ELEMENT_LIST: `${MODULE}remove_subelement_from_client_element_list`,
  REMOVE_SECTION_FROM_SUBELEMENT: `${MODULE}remove_section_from_subelement`,

  ADD_CLIENT_ELEMENT_TO_CLIENT_ELEMENT_LIST: `${MODULE}add_client_element_to_client_element_list`,
  ADD_SUBELEMENT_TO_CLIENT_ELEMENT_LIST: `${MODULE}add_subelement_to_client_element_list`,
  ADD_NACHFOLGER_TO_CLIENT_ELEMENT: `${MODULE}add_nachfolger_to_client_element`,
  ADD_CLAUSE_TO_CURRENT_MANDANT_DOCUMENT: `${MODULE}add_clause_to_current_mandant_document`,

  PUT_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}put_client_element_responsibility`,
  DELETE_TAG_FROM_ALL_CLIENT_ELEMENTS: `${MODULE}delete_tag_from_all_client_elements`,
  DELETE_CLIENT_ELEMENT_RESPONSIBILITY: `${MODULE}delete_client_element_responsibility`,

  UPDATE_CLIENT_ELEMENT_REVISIONS_ARRAY: `${MODULE}update_client_element_revisions_array`,
  UPDATE_TAG_IN_CLIENT_ELEMENTS: `${MODULE}update_tag_in_client_elements`,

  SET_LIVE_VIEW: `${MODULE}set_live_view`,
  SET_LIVE_VIEW_PT_TEXT: `${MODULE}set_live_view_pt_text`,
  SET_LIVE_VIEW_PT_HEADING: `${MODULE}set_live_view_pt_heading`,
  SET_LIVE_VIEW_FONT_FAMILY_TEXT: `${MODULE}set_live_view_font_family_text`,
  SET_LIVE_VIEW_FONT_FAMILY_HEADING: `${MODULE}set_live_view_font_family_heading`,

  SET_AENDERUNGSMODUS: `${MODULE}set_aenderungsmodus`,
  SET_AENDERUNGSMODUS_HISTORY: `${MODULE}set_aenderungsmodus_history`,
  SET_AENDERUNGSMODUS_HISTORY_UPDATE_COUNTER: `${MODULE}set_aenderungsmodus_history_update_counter`,
  SET_AENDERUNGSMODUS_SECTION_REVISION_AND_ADD_PATCH:
    `${MODULE}set_aenderungsmodus_section_revision_and_add_patch`,
  SET_AENDERUNGSMODUS_SELECTED_BASE: `${MODULE}set_aenderungsmodus_selected_base`,
  SET_AENDERUNGSMODUS_SELECTED_HEAD: `${MODULE}set_aenderungsmodus_selected_head`,
  SET_AENDERUNGSMODUS_SECTION_STATE: `${MODULE}set_aenderungsmodus_section_state`,
  PREPARE_CLIENT_ELEMENT_FOR_AENDERUNGSMODUS: `${MODULE}prepare_client_element_for_aenderungsmodus`,
  ADD_COMMENT_TO_PATCH: `${MODULE}add_comment_to_patch`,
  REMOVE_SECTION_FROM_AENDERUNGSMODUS_HISTORY:
    `${MODULE}remove_section_from_aenderungsmodus_history`,
  SET_AENDERUNGSMODUS_ORIGIN_CLIENT_ELEMENT_ORDER:
    `${MODULE}set_aenderungsmodus_origin_client_element_order`,

  SET_LIVE_VIEW_RENDERED: `${MODULE}set_live_view_rendered`,
  SET_LAYOUT_MODE: `${MODULE}set_layout_mode`
};

export default MUTATIONS;
