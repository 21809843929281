import AbstandAbsatz from '@src/model/enums/AbstandAbsatz';
import Ausrichtung from '@src/model/enums/Ausrichtung';
import FusszeileSpaltenlayout from '@src/model/enums/FusszeileSpaltenlayout';
import KopfzeilePositionierung from '@src/model/enums/KopfzeilePositionierung';
import Layout from '@src/model/enums/Layout';
import Umrandung from '@src/model/enums/Umrandung';

export default {
  clientElement: {},
  clientElements: [],
  currentClientElementId: '',

  mandantCompareElement: {},

  clientElementZwangsupdate: {},
  updateIsFinishedCounter: 0,
  revisionsArray: [],

  liveView: false,
  liveViewRendered: false,
  layoutMode: false,
  liveViewDocLayoutName: '',
  liveViewDocLayoutRevision: NaN,
  liveViewDocLayout: {
    layout: Layout.EINSPALTIGES_LAYOUT,

    ueberschriftSchriftgroesse: 24,
    textSchriftgroesse: 12,
    ueberschriftSchriftart: 'Apex New',
    textSchriftart: 'Apex New',

    padding: {
      top: 30,
      bottom: 30,
      right: 20,
      left: 20
    },

    abstandAbsatz: AbstandAbsatz.AUTO,
    ueberschriftAbstand: AbstandAbsatz.AUTO,
    abstandVorUeberschrift: 0,
    abstandSpalten: 4,

    textSchriftFarbe: '#000',
    ueberschriftSchriftFarbe: '#000',

    ueberschriftHintergrundFarbe: '#FFFFFF',
    ueberschriftUnterstrichFarbe: '#000',
    ueberschriftUnterstrichStaerke: 0,
    ueberschriftAusrichtung: Ausrichtung.LINKS,
    ueberschriftSchrifteffekte: [],
    
    // Kopfzeile
    logo: '',
    breiteLogo: 100,
    platzierungLogo: 'left',
    kopfzeileText: '',
    kopfzeileTextGroesse: 12,
    kopfzeileTextFarbe: '#000',
    kopfzeilePositionierung: KopfzeilePositionierung.ALLE_SEITEN,

    // Formular
    formularHintergrundfarbe: '#EFEFEF',
    formularRandPosition: [],
    formularRandStaerke: 0,
    formularRandRadius: 0,
    formularRandFarbe: 'transparent',

    // Tabelle
    tabelleUmrandung: [ Umrandung.HORIZONTAL ],
    tabelleUmrandungStaerke: 1,
    tabelleUmrandungFarbe: '#EFEFEF',
    tabelleHintergrundFarbe: '#FFFFFF',
    tabelleKopfzeileEffekte: [],
    tabelleKopfzeileSchriftFarbe: '#000',
    tabelleKopfHintergrundFarbe: '#FFFFFF',

    // Fusszeile
    fusszeileSpaltenlayout: FusszeileSpaltenlayout.EINS,
    fusszeileTexte: [],
    fusszeileNummerierung: Ausrichtung.RECHTS,
    fusszeileTexteGroesse: 12,
    fusszeileTexteFarbe: '#000'
  },

  liveViewDocFormularLayouts: {},
  selectedLiveViewFormularInputElement: {},
  liveViewDocFormularLayoutRevision: 0,

  liveViewSectionLayouts: [],
  liveViewSectionLayoutsChangedCounter: 0,

  aenderungsmodus: false,
  aenderungsmodusHistory: {
    order: [],
    sections: {}
  },
  aenderungsmodusHistoryUpdateCounter: 0,
  aenderungsmodusOriginClientElement: {},
  aenderungsmodusSelectedBase: {},
  aenderungsmodusSelectedHead: {}
};

export const component = 'mandantEditor/';
